import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import rehypeRaw from 'rehype-raw';

const Wrapper = styled.div`
  padding: 10% 15% 7% 17%;

  a {
    ${({ theme }) => theme.typography.link()};
    color: ${({ theme }) => theme.color.textRed};
    background-color: none;
    transition: color 0.1s;

    &:hover {
      color: ${({ theme }) => theme.color.titleLightBlue};
    }
  }

  @media (max-width: ${({ theme }) => theme.device.tablet}) {
    padding: 10%;
    padding-top: 8rem;
  }

  p.title {
    ${({ theme }) => theme.typography.footerSubHeadline()};
    font-weight: 700;
    margin-bottom: 20px;

    @media (max-width: ${({ theme }) => theme.device.tablet}) {
      ${({ theme }) => theme.typography.titleBar()};
    }
  }

  p.subTitle {
    ${({ theme }) => theme.typography.titleContent()};
    margin-bottom: 1.6em;
    margin-top: 1.6em;
  }

  p.detail {
    ${({ theme }) => theme.typography.link()};
    margin-bottom: 1.6em;
    line-height: 27px;
    /* white-space: pre-line; */
  }

  ul {
    margin-left: 3em;
  }

  li {
    margin: 0;
  }
`;

const PrivacyWrapper = ({ markdown = '' }) => {
  return (
    <Wrapper>
      <ReactMarkdown rehypePlugins={[rehypeRaw]} children={markdown} escapeHtml={false} />
    </Wrapper>
  );
};

export default PrivacyWrapper;
